import { Link } from "@inertiajs/react";
import { Nav } from "react-bootstrap";

export default function NavbarNavLeft() {
    return (
        <Nav className="me-auto mb-lg-0">
            <Nav.Item>
                <Nav.Link
                    as={Link}
                    href={route("pages.icons")}
                    active={route().current("pages.icons") || route().current("pages.icons.collection")}
                >
                    Icons + Symbols
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
}
