import { Link } from "@inertiajs/react";
import Logo from "./Logo";

export default function Footer() {
    return (
        <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-4 py-5 my-5 border-top">
            <div className="mb-3">
                <Link
                    href={route("pages.home")}
                    className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none"
                >
                    <Logo />
                </Link>
                <p className="text-body-secondary small">
                    Your rapid development powerhouse. <br />
                    &copy; 2024 InterlaceKit. All rights reserved. <br />A{" "}
                    <a
                        className="fw-normal"
                        target="_blank"
                        rel="noopener"
                        href="https://priyashpatil.com/ventures"
                    >
                        priyashpatil/ventures
                    </a>{" "}
                    initiative.
                </p>
            </div>

            <div className="col mb-3"></div>

            <div className="col mb-3">
                <h5>Links</h5>
                <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                        <Link
                            href={route("pages.home")}
                            className="nav-link p-0 text-body-secondary"
                        >
                            Home
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <a
                            className="nav-link p-0 text-body-secondary"
                            href="mailto:hello@interlacekit.com"
                        >
                            Support
                        </a>
                    </li>
                    {/* <li className="nav-item mb-2">
                        <a
                            href="#"
                            className="nav-link p-0 text-body-secondary"
                        >
                            Affiliates — Earn up to $30 per sale
                        </a>
                    </li> */}
                </ul>
            </div>

            <div className="col mb-3">
                <h5>Legal</h5>
                <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                        <Link
                            href={route("pages.privacy-policy")}
                            className="nav-link p-0 text-body-secondary"
                        >
                            Privacy Policy
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <Link
                            href={route("pages.terms-of-service")}
                            className="nav-link p-0 text-body-secondary"
                        >
                            Terms of Service
                        </Link>
                    </li>
                    <li className="nav-item mb-2">
                        <Link
                            href={route("pages.refund-policy")}
                            className="nav-link p-0 text-body-secondary"
                        >
                            Refund Policy
                        </Link>
                    </li>
                </ul>
            </div>
        </footer>
    );
}
