import { PageProps } from "@/types";
import { Link, useForm, usePage } from "@inertiajs/react";
import { Nav, NavDropdown } from "react-bootstrap";

export default function NavbarNavRight() {
    const { auth } = usePage<PageProps>().props;
    const form = useForm();
    const submit = () => {
        form.post(route("logout"));
    };

    return (
        <Nav className="ms-auto mb-lg-0">
            <Nav.Item>
                <Nav.Link href="mailto:hello@interlacekit.com">
                    Support
                </Nav.Link>
            </Nav.Item>
            {!auth.user ? (
                <>
                    <Nav.Item>
                        <Nav.Link as={Link} href={route("register")}>
                            Register
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} href={route("login")}>
                            Login
                        </Nav.Link>
                    </Nav.Item>
                </>
            ) : (
                <NavDropdown title="Account" align={"end"}>
                    <div className="d-flex px-3 gap-2">
                        {auth.user.avatar ? (
                            <img
                                src={auth.user.avatar || ""}
                                alt={`${auth.user.name}'s avatar`}
                                height="42px"
                                width="42px"
                                className="bg-dark me-1 rounded-circle"
                            />
                        ) : (
                            <div
                                className="me-1 rounded-circle bg-dark-subtle"
                                style={{
                                    height: "42px",
                                    width: "42px",
                                }}
                            ></div>
                        )}

                        <div>
                            <div className="lh-1">{auth.user.name}</div>
                            <div className="small text-muted">
                                {auth.user.email}
                            </div>
                            <div>
                                <span className="badge text-bg-dark">
                                    Tier {auth.user.tier}
                                </span>
                            </div>
                        </div>
                    </div>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} href={route("account.billing")}>
                        Billing &amp; Limits
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        as={Link}
                        href={route("account.access-tokens")}
                    >
                        Access Tokens
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => submit()}>
                        Logout
                    </NavDropdown.Item>
                </NavDropdown>
            )}
        </Nav>
    );
}
