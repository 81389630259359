import { Link } from "@inertiajs/react";

interface VerificationNoticeProps {
    show: boolean;
}

export default function VerificationNotice({ show }: VerificationNoticeProps) {
    if (!show) {
        return null;
    }

    return (
        <div
            className="container-fluid text-bg-warning text-center small py-2"
            role="alert"
        >
            Verify your email address to unlock all features. Click here to{" "}
            <Link href={route("verification.notice")}>Resend</Link>
        </div>
    );
}
